import React from 'react';

type HighlightTextProps = {
  term: string;
  text: string;
  component?: any;
  diacriticSensitive?: boolean;
};

// from mark.js
function createDiacriticsRegExp(str: string, caseSensitive: boolean = false) {
  const sens = caseSensitive ? '' : 'i';
  const dct = caseSensitive
    ? [
        'aàáảãạăằắẳẵặâầấẩẫậäåāą',
        'AÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬÄÅĀĄ',
        'cçćč',
        'CÇĆČ',
        'dđď',
        'DĐĎ',
        'eèéẻẽẹêềếểễệëěēę',
        'EÈÉẺẼẸÊỀẾỂỄỆËĚĒĘ',
        'iìíỉĩịîïī',
        'IÌÍỈĨỊÎÏĪ',
        'lł',
        'LŁ',
        'nñňń',
        'NÑŇŃ',
        'oòóỏõọôồốổỗộơởỡớờợöøō',
        'OÒÓỎÕỌÔỒỐỔỖỘƠỞỠỚỜỢÖØŌ',
        'rř',
        'RŘ',
        'sšśșş',
        'SŠŚȘŞ',
        'tťțţ',
        'TŤȚŢ',
        'uùúủũụưừứửữựûüůū',
        'UÙÚỦŨỤƯỪỨỬỮỰÛÜŮŪ',
        'yýỳỷỹỵÿ',
        'YÝỲỶỸỴŸ',
        'zžżź',
        'ZŽŻŹ',
      ]
    : [
        'aàáảãạăằắẳẵặâầấẩẫậäåāąAÀÁẢÃẠĂẰẮẲẴẶÂẦẤẨẪẬÄÅĀĄ',
        'cçćčCÇĆČ',
        'dđďDĐĎ',
        'eèéẻẽẹêềếểễệëěēęEÈÉẺẼẸÊỀẾỂỄỆËĚĒĘ',
        'iìíỉĩịîïīIÌÍỈĨỊÎÏĪ',
        'lłLŁ',
        'nñňńNÑŇŃ',
        'oòóỏõọôồốổỗộơởỡớờợöøōOÒÓỎÕỌÔỒỐỔỖỘƠỞỠỚỜỢÖØŌ',
        'rřRŘ',
        'sšśșşSŠŚȘŞ',
        'tťțţTŤȚŢ',
        'uùúủũụưừứửữựûüůūUÙÚỦŨỤƯỪỨỬỮỰÛÜŮŪ',
        'yýỳỷỹỵÿYÝỲỶỸỴŸ',
        'zžżźZŽŻŹ',
      ];
  const handled: string[] = [];
  let result = str;
  result.split('').forEach(ch => {
    dct.every(dctItem => {
      if (dctItem.indexOf(ch) !== -1) {
        if (handled.indexOf(dctItem) > -1) {
          return false;
        }

        const pattern = `[${dctItem}]`;
        const flags = `gm${sens}`;
        result = result.replace(new RegExp(pattern, flags), pattern);

        handled.push(dctItem);
      }

      return true;
    });
  });
  return result;
}

const HighlightText = ({
  term = '',
  text = '',
  component: Component = 'span',
  diacriticSensitive = false,
}: HighlightTextProps) => {
  const termCleaned = term
    .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&') // Wrap special character
    .split(' ')
    .join('|'); // for RegExp multiple words

  const pattern = new RegExp(
    diacriticSensitive ? termCleaned : createDiacriticsRegExp(termCleaned),
    'ig',
  );
  const html = text.replace(
    pattern,
    matchText =>
      `<em data-testid='highlighted-autocomplete-text'>${matchText}</em>`,
  );
  return (
    <Component // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
export default HighlightText;
